<template>
  <div>
    <ShippingBoxForHome 
      :language="language"
      :shipping-language="language"
      :shipping-info="shippingInfo"
      :shipping-policy="shippingPolicy"
      :free-shipping-threshold="freeShippingThreshold"
      :free-shipping-methods="freeShippingMethods"
    />
  </div>
</template>

<script>
// common
import mixins from 'public/src/pages/components/ccc/components/mixins/mixin.js'
import schttp from 'public/src/services/schttp'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import { getLocalStorage } from '@shein/common-function'

// 组件
import ShippingBoxForHome from 'public/src/pages/goods_detail/middlect/components/ShippingBoxForHome.vue'

const { lang } = gbCommonInfo
export default {
  name: 'ShippingBox',
  components: {
    ShippingBoxForHome
  },
  mixins: [mixins],
  props: {
    ...commonProps,
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    drawerVisible: {
      type: Boolean,
      default: false
    },
    freeShippingThreshold: {
      type: String,
      default: ''
    },
    freeShippingMethods: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      shippingPolicy: [],
      shippingInfo: {},
      shippingAbt: 'typeA',
    }
  },
  computed: {
  },
  watch: {
    drawerVisible: {
      immediate: true,
      handler (newVal) {
        if(newVal) {
          this.getShippingDetail()
        }
      }
    }
  },
  activated() {
  },
  mounted() {
    this.getAbt()
    
  },
  destroyed() {
  },
  methods: {
    async getAbt() {
      const { shipping } = await getUserAbtData()
      this.shippingAbt = shipping?.p
    },
    getSorted (data = []) {
      // 按邮寄方式
      function sortTypeFun () {
        let dic = lang !== 'ru' ? {
          'express_shipping': 3,
          'stand_shipping': 2,
          'shop_transit': 1,
          'expedited_shipping': 0
        } : {
          'express_shipping': 2,
          'stand_shipping': 1,
          'shop_transit': 3,
          'expedited_shipping': 0
        }
        return (a, b) => {
          return dic[b.type] - dic[a.type]
        }
      }
      // 按价格排序
      function sortPriceFun () {
        let dic = {
          'express_shipping': 1,
          'stand_shipping': 2,
          'shop_transit': 0,
          'expedited_shipping': 3
        }
        return (a, b) => {
          const value1 = a['starting_price_obj'] && a['starting_price_obj']['amount'] || 0
          const value2 = b['starting_price_obj'] && b['starting_price_obj']['amount'] || 0
          if (value1 === value2) {
            return dic[b.type] - dic[a.type]
          } else {
            return value1 - value2
          }
        }
      }
      // TODO: 取abt shipping['param'] || 'typeA' , 旧平台posKeys: 'shipping'
      let sortFun = this.shippingAbt === 'quick' ? sortTypeFun() : sortPriceFun() // 排序方式
      return data.sort(sortFun)
    },
    /**
     * 请求物流信息
     */
    async getShippingDetail () {
      const addressCookie = getLocalStorage('addressCookie')
      let [shippingInfo = {}, cache] = [{}, null]
      if(addressCookie) {
        cache = typeof addressCookie == 'string' ? JSON.parse(addressCookie) : addressCookie
      }
      // const cache = {"countryId":"108","stateId":"113178","cityId":"185355","districtId":"185364","createdTime":1692085858223,"isUserHandle":"1","siteUid":"mjp"}
      if(cache) {
        const addressCacheStr = window?.localStorage?.getItem('address_cache')
        const addressCache = addressCacheStr && JSON.parse(addressCacheStr)
        // const addressCache = {"108": '日本', "185355":"名古屋市千種区","113178":"愛知県","185364":"朝岡町","postcode":""}
        shippingInfo = {
          address_id: cache.addressId,
          country_id: cache.countryId,
          state_id: cache.stateId,
          city_id: cache.cityId,
          district_id: cache.districtId,
          country: addressCache?.[cache.countryId] || '',
          state_province: addressCache?.[cache.stateId] || '',
          city: addressCache?.[cache.cityId] || '',
          district: addressCache?.[cache.districtId] || ''
        }
      }
      const resData = await schttp({
        method: 'GET',
        url: '/api/productInfo/shippingDetail/get',
        params: { ...shippingInfo },
      })
      const { shippingMethods = [] } = resData || {}
      let shippingPolicy
      if (shippingMethods && shippingMethods.length) {
        shippingPolicy = shippingMethods.map((item) => {
          return {
            hasZeroThreshold: item.has_zero_threshold,
            title: item.title || '',
            type: item.type,
            transportInterval: item.transport_interval,
            fullAmount: item.full_amount,
            currencyCode: item.currency_code,
            expectTime: item.expect_time,
            descType: item.desc_type,
            shippingMold: item.shipping_mold,
            shippingPrices: item.shippingPrices,
            startingPriceObj: item.starting_price_obj,
            stepPriceObj: item.step_price_obj,
            lastPriceObj: item.last_price_obj || '',
            currentTime: item.current_time || '',
            aging_is_show: item.aging_is_show || '',
            aging_is_expose: item.aging_is_expose || '',
            ...item
          }
        })
      }
      if(Array.isArray(shippingPolicy)) {
        this.shippingPolicy = this.getSorted(shippingPolicy)
      }
      this.shippingInfo = shippingInfo
    },
  }
}
</script>


<template>
  <div>
    <div
      v-if="!hideComp"
      :class="{
        'free-shipping__container': true,
        'only-single': isShowSingleStyle,
      }"
      :style="containerStyle"
    >
      <div
        v-if="isNewUserStyle && showItems.length"
        class="free-shipping-right__swiperRight"
        :class="{
          'is-single-newUser': onlySingleStylePage
        }"
      >
        <!-- 判断item.length -->
        <swiper-container
          ref="swiperReferenceRight"
          :style="{ 'height': '100%' }"
          init="false"
        >
          <!-- 多个item 非券 列表页不展示 -->
          <swiper-slide
            v-if="isMultiLine(showItems) && !onlySingleStylePage"
            class="free-shipping__inner"
            :style="newUserStyle"
          > 
            <div 
              class="has-border" 
              :style="borderNewStyle"
            >
            </div>
            <div 
              v-if="metaData?.freeShippingBackgroundImage?.src"
              :style="{
                position: 'absolute',
                width: '100%',
                height: '100%',
                background: `url(${metaData?.freeShippingBackgroundImage?.src})`,
                backgroundSize: 'cover',
              }"
            >
            </div>
            <div class="free-shipping-left no-spacing">
              <FreeShippingList 
                :key="`slide-${showItems[0].type}-${showItems[0].hrefType}`" 
                class="free-shipping j-free-shipping"
                :class="{
                  'is-fixed': isShowSingleStyle,
                }"
                :style="{
                  borderRadius: 0,
                }"
                :items="showItems.slice(0,1)"
                :show-items="showItems"
                :language="language.freeshipping" 
                :get-analysis-data="getAnalysisData" 
                :is-sticky="onlySingleStylePage"
                :meta-data="metaData"
                v-bind="injectData"
                @clickCoupon="couponClickJump"
                @updateParentChange="updateParentChange"
              />
            </div>
            <div class="free-shipping-right no-spacing">
              <FreeShippingList 
                :key="`slide-${showItems[1].type}-${showItems[1].hrefType}`"
                class="free-shipping j-free-shipping" 
                :class="{
                  'is-fixed': onlySingleStylePage,
                }"
                :style="{
                  borderRadius: 0,
                }"
                :items="showItems.slice(1,2)"
                :show-items="showItems"
                :language="language.freeshipping" 
                :get-analysis-data="getAnalysisData" 
                :is-sticky="onlySingleStylePage"
                :meta-data="metaData"
                v-bind="injectData"
                @clickCoupon="couponClickJump"
                @updateParentChange="updateParentChange"
              />
            </div>
          </swiper-slide>
          <!-- 单个item 非券 注意区分 首页单个Item 和 列表页 展示不一致-->
          <swiper-slide
            v-else
            :style="newUserStyle"
          >
            <FreeShippingList 
              v-if="showItems.length"
              :key="`slide-${showItems[0].type}-${showItems[0].hrefType}`"
              class="free-shipping j-free-shipping" 
              :class="{
                'is-fixed': true,
              }"
              :style="singleStyle"
              :items="showItems.slice(0,1)"
              :show-items="showItems"
              :language="language.freeshipping" 
              :is-new-user-style="onlySingleStylePage && isNewUserStyle"
              :page-from="pageFrom" 
              :only-single-style-page="onlySingleStylePage"
              :get-analysis-data="getAnalysisData" 
              :is-sticky="true"
              :meta-data="metaData"
              v-bind="injectData"
              @clickCoupon="couponClickJump"
              @updateParentChange="updateParentChange"
            />
          </swiper-slide>
          <!-- 固定新样式 券 首页-列表 --> 
          <!-- 取最后一帧 -->
          <swiper-slide v-if="isHasNewUserCoupon(showItems).length > 0">
            <FreeShippingList 
              key="slide-newUserCoupon"
              :isHasNewUserCoupon="isHasNewUserCoupon(showItems).length > 0"
              :isNewUserStyle="isNewUserStyle"
              class="free-shipping j-free-shipping" 
              :class="{
                'is-fixed': false,
              }"
              :style="{
                background:'none',
                borderRadius: pageFrom === 'list' ? '0px' : '3px',
              }"
              :items="isHasNewUserCoupon(showItems)"
              :language="language.freeshipping" 
              :get-analysis-data="getAnalysisData" 
              :is-sticky="onlySingleStylePage"
              :page-from="pageFrom" 
              :meta-data="metaData"
              v-bind="injectData"
              @clickCoupon="couponClickJump"
              @updateParentChange="updateParentChange"
            />
          </swiper-slide>
        </swiper-container>
        <!-- 新人样式 -->
        <NewUserText
          v-if="!onlySingleStylePage"
          :language="language.freeshipping"
          :bgColor="{
            labelBgColorStart: metaData?.labelBgColorStart,
            labelBgColorEnd: metaData?.labelBgColorEnd,
          }"
        />
      </div>
      <template v-if="!isNewUserStyle">
        <div
          v-if="onlySingleStylePage || isSingleRotateMode"
          class="j-free-shipping free-shipping__stickyInner free-shipping__onlySingleStylePage"
        >
          <swiper-container
            ref="stickySwiper"
            class="free-shipping__stickyInnerSwiper"
            init="false"
          >
            <swiper-slide 
              v-for="(item, index) in showItems"
              :key="`slide-${item.type}-${item.hrefType}-${index}`"
              class="free-shipping__stickyInnerSlide swiper-no-swiping"
            >
              <FreeShippingList 
                class="free-shipping is-fixed" 
                :items="[item]"
                :is-sticky="true"
                :language="language.freeshipping" 
                :get-analysis-data="getAnalysisData" 
                :custom-component-name="customComponentName"
                v-bind="injectData"
                @clickCoupon="couponClickJump"
                @updateParentChange="updateParentChange"
              />
            </swiper-slide>
          </swiper-container>
        </div>
        <div
          v-else
          class="free-shipping__inner"
          :class="{
            'is-single': isShowSingleStyle
          }"
        >
          <div class="free-shipping-left">
            <!-- case1. showItems只有一个，展示吸顶样式; case2.showItems有2个或以上，第一个都固定展示; case3.页面是category复用吸顶样式-->
            <FreeShippingList 
              v-if="showItems.length"
              class="free-shipping j-free-shipping" 
              :class="{
                'is-fixed': isShowSingleStyle,
              }"
              :items="showItems.slice(0,1)"
              :language="language.freeshipping" 
              :get-analysis-data="getAnalysisData" 
              :is-sticky="isShowSingleStyle"
              v-bind="injectData"
              @clickCoupon="couponClickJump"
              @updateParentChange="updateParentChange"
            />
          </div>
          <div class="free-shipping-right">
            <!-- showItems有2个: 第2个右边固定 有3个:除第一个外剩下的右边轮播 -->
            <FreeShippingList
              v-if="showItems.length === 2" 
              class="free-shipping j-free-shipping" 
              :items="showItems.slice(1,2)"
              :language="language.freeshipping"
              :get-analysis-data="getAnalysisData"
              v-bind="injectData"
              @clickCoupon="couponClickJump"
              @updateParentChange="updateParentChange"
            />
            <section
              v-if="showItems.length > 2"
              class="free-shipping-right__culSwiper" 
            >
              <swiper-container
                ref="swiperReferenceRight"
                class="free-shipping-right__swiperRight"
                init="false"
              >
                <swiper-slide 
                  v-for="(item, index) in showItems.slice(1)"
                  :key="`slide-${item.type}-${item.hrefType}-${index}`"
                  class="free-shipping-right__swiperRightSlide swiper-no-swiping"
                >
                  <FreeShippingList
                    :key="`slide-${item.type}-${item.hrefType}`"
                    ref="FreeShippingListSwiper"
                    :data-swiperIndex="index"
                    class="free-shipping"
                    :items="[item]"
                    :language="language.freeshipping"
                    :get-analysis-data="getAnalysisData"
                    v-bind="injectData"
                    @updateParentChange="updateParentChange"
                    @clickCoupon="couponClickJump"
                  />
                </swiper-slide>
              </swiper-container>
            </section>
          </div>
        </div>
      </template>
    </div>
    <!-- 弹窗 -->
    <ClientOnlySlot>
      <div>
        <LazyMount>
          <CouponInfoDrawer
            :visible.sync="drawerComp.show" 
            :coupon-info="drawerComp.coupon"
            :language="{...language.couponItem,...language.freeshipping}"
            :title="drawerComp.title"
            :item="drawerComp.item"
            :page="pageFrom"
          />
        </LazyMount>
  
        <LazyMount>
          <FreeshippingDrawer
            :visible.sync="freeshippingComp.show" 
            :language="{...language.couponItem,...language.freeshipping}"
            :item="freeshippingComp.item"
            :coupon-info="freeshippingComp.coupon"
            :is-show-shipping-coupon="freeshippingComp.isShowShippingCoupon"
            :site-free-mall-info="injectData.siteFreeMallInfo"
          />
        </LazyMount>
      </div>
    </ClientOnlySlot>
  </div>
</template>
<script>
import mixins from 'public/src/pages/components/ccc/components/mixins/mixin.js'
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import { hexToRGBA } from 'public/src/pages/components/ccc/common/utils.js'
// import { getServerTime } from 'public/src/pages/components/ccc/common/utils.js'
import { cccxEventBus } from 'public/src/pages/components/ccc/common/utils.js'
import { windowLinstnerScrollEventInstance } from 'public/src/services/expose/index.js'
import { getCartFloor, getNewUserFreeShipping, getFreeShippingCouponInfo, extendBury } from './utils.js'
import { getCccPurchaseCouponBannerCompData } from 'public/src/services/api/ccc.js'

// 组件
import StickyFreeShippingInstance from './sticky-free-shipping.js'
import NewUserText from './atomic-component/NewUserText.vue'
import FreeShippingList from './FreeShippingList.vue'
import ClientOnlySlot from 'public/src/pages/components/ccc/base/ClientOnlySlot.vue'
import CouponInfoDrawer from './CouponInfoDrawer.vue'
import FreeshippingDrawer from './FreeshippingDrawer.vue'

import { LazyMount } from '@shein/sui-mobile'
import expose from 'public/src/services/expose/index.js'
import { Autoplay } from 'swiper/modules'
import { register } from 'swiper/element'
typeof window !== 'undefined' && register()
const PAGE_MAP = {
  'homePage': 'homePage', // 多渠道Tab的首页
  'campaigns': 'campaigns', // 专题页
}
// TODO: 免邮有用到多语言
export default {
  name: 'FreeShipping',
  components: {
    NewUserText,
    FreeShippingList,
    ClientOnlySlot,
    CouponInfoDrawer,
    FreeshippingDrawer,
    LazyMount
  },
  mixins: [mixins],
  props: {
    ...commonProps,
  },
  data() {
    const { props } = this?.propData
    const { abtInfoObj } = this.context
    const { items, metaData = {}  } = props
    return {
      doms: {},
      showItems: extendBury(items),
      hideComp: false,
      injectData: {
        propData: this.propData,
        sceneData: this.sceneData,
        context: this.context,
        cartBriefInfo: null,
        siteFreeMallInfo: null,
        freeShippingInfo: null,
      },
      disableBottomSpacing: metaData?.disableBottomSpacing,
      listenId: null,
      stickyTop: 0, // 吸顶距离
      isNeedSticky: false, // 是否需要吸顶
      calcStickycalcStickyThrottling: false,
      drawerComp: { // 显示优惠券的弹窗信息
        show: false,
        title: '',
        coupon: [],
        item: {}, // 当前ccc配置的item，用到里面的type
      },
      freeshippingComp: { // 免邮组件弹窗信息
        show: false,
        coupon: [],
        item: {},
        isShowShippingCoupon: false,
      },
      memberCouponListParams: { // 请求用户优惠券列表的参数
        type: 1, //默认1可用券
        category: 0, //默认0全部
        timeStatus: 2, //默认2临期
      },
      abtInfoObj,
      swiperSlideIndex: 0,
      stickySwiperSlideIndex: 0,
      stickyDeactivatedIndex: 0, // 失活页面时的activeIndex
      shouldUpdateData: false, // 购物车变化后此值为true, 应该重新更新接口数据
    }
  },
  computed: {
    options() {
      return {
        direction: 'vertical',
        modules: [Autoplay],
        loop: false,
        initialSlide: this.swiperSlideIndex,
        allowTouchMove: false,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        unAutoInit: true,
        noSwiping: true,
        noSwipingClass: 'swiper-no-swiping',
        autoplayOutsideControl: true,
        on: {
          init: () => {
            this.observeSwiper('right')
          },
          activeIndexChange: (swiper) => {
            // 保存当前轮播图的activeIndex, 下次轮播开始的位置
            this.swiperSlideIndex = swiper.activeIndex
            StickyFreeShippingInstance.update(this.getStickyCompProps())
          }
        }
      }

    },
    stickyOptions() {
      return {
        direction: 'vertical',
        modules: [Autoplay],
        loop: false,
        initialSlide: this.stickySwiperSlideIndex,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        unAutoInit: true,
        noSwiping: true,
        noSwipingClass: 'swiper-no-swiping',
        autoplayOutsideControl: true,
        on: {
          init: () => {
            this.observeSwiper('sticky')
          },
          activeIndexChange: (swiper) => {
            // 保存当前轮播图的activeIndex, 下次轮播开始的位置
            this.stickySwiperSlideIndex = swiper.activeIndex
            StickyFreeShippingInstance.update(this.getStickyCompProps())
          }
        }
      }

    },
    isShowSingleStyle() {
      return this.showItems.length === 1
    },
    pageFrom() {
      return this.sceneData?.pageFrom
    },
    onlySingleStylePage() { // 仅使用单行吸顶样式
      const singleStylePageList = ['category', 'list', 'wishlist']
      return singleStylePageList.includes(this.pageFrom)
    },
    // 是否可以立即更新购物车数据
    canUpdateCartinfo() {
      const pageList = ['list', 'wishlist']
      return pageList.includes(this.pageFrom)
    },
    isCardStyle() {
      return this.metaData?.isCardShow == '1' && Array.isArray(this.metaData?.margin) && this.metaData.margin.some((item) => item > 0)
    },
    cardStyle() {
      if(!this.isCardStyle) return {}
      const marginList =
        Array.isArray(this.metaData?.margin) && this.metaData?.margin.length === 4
          ? this.metaData.margin.slice()
          : Array(4).fill(0)
      const marginStyle = marginList
        .map((item) => `${Number(item)}px`)
        .join(' ')
      const styleResult = {
        margin: marginStyle
      }
      return styleResult
    },
    newUserStyle() {
      const style = {
        'border-radius': '0px',
      }
      if (!this.onlySingleStylePage) {
        style['border'] = `0.5px solid ${hexToRGBA(this.metaData.freeShippingColor, 0.2)}`
        style['border-radius'] = '3px'
      }
      return style
    },
    singleStyle() {
      const style = {
        height: '100%',
      }
      if(this.metaData?.freeShippingBackgroundImage?.src) {
        style['border'] = 'none'
        style['background-image'] = `url(${this.metaData.freeShippingBackgroundImage?.src})`
        style['background-size'] = '100% 100%'
      }
      return style
    },
    borderNewStyle() {
      return {
        'background': hexToRGBA(this.metaData.freeShippingColor, 0.2),
      }
    },
    isNewUserStyle() {
      return this.propData?.props?.metaData?.isNewUserStyle == '1'
    },
    isSingleRotateMode() {
      return this.abtInfoObj?.single_rotate_mode == '1'
    },
    // 埋点， 吸顶自定义的组件名称
    customComponentName() {
      // 导航页非新客样式条，埋点字段调整
      if(this.pageFrom === 'category') {
        return 'policy_floating'
      } 
      return ''
    },
    mbClass() {
      if(['category', 'list', 'wishlist'].includes(this.pageFrom)) {
        return '0px'
      } else if(this.isCardStyle && Array.isArray(this.metaData?.margin) && this.metaData?.margin.length === 4) {
        return `${this.metaData?.margin?.[2] || 0}px`
      } else if(!this.isCardStyle && !this.metaData?.disableBottomSpacing) {
        return '10px'
      }
      return '0px'
    },
    containerStyle() {
      if(this.onlySingleStylePage) {
        return {
          marginBottom: this.mbClass,
        }
      } else {
        return {
          margin: '0 8px',
          ...this.cardStyle,
          marginBottom: this.mbClass,
        }
      }
    },
  },
  watch: {
    'isNeedSticky': {
      immediate: true,
      handler(newVal) {
        this.$nextTick(() => {
          this.isNeedStickyFn(newVal)
        })
      }
    },
    'stickyTop': {
      
      // stickyTop 可能计算在后，然后导致show的时候传入进去的stickyTop不正确，修正一下
      handler() {
        this.isNeedStickyFn(this.isNeedSticky)
      }
    },
    '$route': function (to, from) {
      // 二级首页进入或者离开时，隐藏吸顶
      if (to.path.endsWith('/secondaryhome') || from.path.endsWith('/secondaryhome')) {
        StickyFreeShippingInstance.hidden()
      }
    }
  },
  activated() {
    this.doms = {}
    if(this.shouldUpdateData) {
      this.updateCartinfo()
    }
    this.isNeedStickyFn(this.isNeedSticky)
    this.initSwipper('swiperReferenceRight')
    this.initSwipper('stickySwiper')
  },
  mounted() {
    if(!this.onlySingleStylePage) { // 服务端取数
      this.listnerScrollSticky()
      this.calcStickyTop()
    }
    this.initJudgeShow(this.injectData)
    // 监听券包弹窗关闭, 重新请求券数据
    cccxEventBus?.on?.('couponPackage-bindCouponSuccess', async (preload) => {
      console.log('监听到券包绑定成功事件分发', preload)
      if (this.injectData?.purchaseCouponInfo && this.injectData?.purchaseCouponInfo?.couponEmpty === '0') {
        const { info: purchaseCouponInfo } = await getCccPurchaseCouponBannerCompData({
          hashCode: this.injectData?.purchaseCouponInfo?.hashCode ?? '',
          pageScene: 'list' // 后端识别列表的标识
        })
        this.updatePurchaseCouponInfo(purchaseCouponInfo) 
        const result = await getNewUserFreeShipping({ sense: this.pageFrom })
        if (result.code == '0' && result?.info?.couponDetailList) {
          this.showItems = this.showItems.map(item => {
            if (item.isNewUserFrame	 === '1') {
              item.couponInfoList = result?.info?.couponDetailList
            }
            return item
          })
        }
      }
  
    })
    // 监听免邮页面隐藏
    cccxEventBus?.on?.('freeShippingPage-will-hidden', () => {
      // 重制dom,以免计算到其他页面top值
      this.doms = {}
      this.calcStickyTop()
      StickyFreeShippingInstance.hidden()
      this.$nextTick(() => {
        this.judgeToSticky()
        // 同步在判断展示的组件
        // this.initJudgeShow(this.injectData)
      })
    })
    this.initSwipper('swiperReferenceRight')
    this.initSwipper('stickySwiper')
  },
  methods: {
    // 是否存在新人优惠券
    isHasNewUserCoupon(items) {
      return items?.filter(item => item.isNewUserFrame == '1' && item.couponDetailList?.length > 0)
    },
    isMultiLine(items) {
      // 是否多行
      return items?.filter(item => item.isNewUserFrame != '1').length > 1
    },
    // 监听swiper 是否出现在适口
    observeSwiper(type) {
      this.obInstance = new expose({ observeHide: true })
      this.obInstance.observe({
        elements: this.$el,
        once: false,
      }, ({ exposeDoms }) => {
        if (exposeDoms.length) {
          // 进入视口
          this.handleEnterViewport(type)
        } else {
          // 离开视口
          this.handleLeaveViewport(type)
        }
      })
    },
    isNeedStickyFn(isNeedSticky) {
      if(isNeedSticky) {
        if(this.stickyTop < 0) {
          return
        }
        setTimeout(() => {
          StickyFreeShippingInstance.show(this.getStickyCompProps())
        })
      }else {
        setTimeout(() => {
          const stickyActiveIndex =  StickyFreeShippingInstance.hidden()
          if (stickyActiveIndex !== undefined) {
            this.stickySwiperSlideIndex = stickyActiveIndex
          }
        })
      }
    },
    // 初始化 swiper
    initSwipper(ref) {
      // swiper element
      const swiperEl = this.$refs[ref]
      if (!swiperEl) return
      Object.assign(swiperEl, ref === 'swiperReferenceRight' ? this.options : this.stickyOptions)
      swiperEl.initialize()
    },
    handleEnterViewport(type) {
      if(type === 'right') {
        this.$refs.swiperReferenceRight?.swiper?.autoplay?.start?.()
      } else if(type === 'sticky') {
        // 更新activeIndex
        this.$refs.stickySwiper?.swiper?.slideTo?.(this.stickySwiperSlideIndex, 0)
        this.$refs.stickySwiper?.swiper?.autoplay?.start?.()
      }
    },
    handleLeaveViewport(type) {
      if(type === 'right') {
        this.$refs.swiperReferenceRight?.swiper?.autoplay?.stop?.()
      } else if(type === 'sticky') {
        this.$refs.stickySwiper?.swiper?.autoplay?.stop?.()
      }
    },
    /**
     * 计算各种页面吸顶的距离
     * j-common-logo-header 公共头部
     * j-homepage-toptab 首页有topbar
     */
    calcStickyTop() {
      setTimeout(() => {
        if(this.pageFrom === PAGE_MAP['homePage'] || this.pageFrom === PAGE_MAP['campaigns'] ) {
          // 首页和专题页 （专题无j-homepage-toptab取0）
          const logoHeader = document.querySelector('.j-common-logo-header')
          const homeTopTab = document.querySelector('.j-homepage-toptab')
          const top = (logoHeader?.offsetHeight || 0) + (homeTopTab?.offsetHeight || 0)
          this.stickyTop = top - 3
          // 二级首页顶导
          const secondaryHomeNavigation = document.querySelector('.secondaryhome-nav')
          if (secondaryHomeNavigation) {
            let secondaryHomeNavigationTop = secondaryHomeNavigation?.offsetHeight || logoHeader?.offsetHeight || 0
            this.stickyTop = secondaryHomeNavigationTop - 2
          }
        }
      })
    },
    /**
     * 绑定滚动吸顶
     */
    listnerScrollSticky() {
      this.listenId = windowLinstnerScrollEventInstance.listen(() => {
        this.judgeToSticky()
      })
    },
    /**
     * 判断是否需要吸顶
     */
    judgeToSticky() {
      // if(this.calcStickycalcStickyThrottling) return
      // this.calcStickycalcStickyThrottling = true

      if (this.pageFrom === PAGE_MAP['homePage'] || this.pageFrom === PAGE_MAP['campaigns'] ) {
        // 首页和专题基准元素:
        // 首页取到j-homepage-toptab ｜ 专题取到j-common-logo-header
        if (!this.doms.relateTopElement) {
          this.doms.relateTopElement = document.querySelector('.j-homepage-toptab') || document.querySelector('.j-common-logo-header')
        }
        

        if (!this.doms.stickyJudge) {
          this.doms.stickyJudge = this.$el.querySelector('.j-free-shipping')
        }
        // j-homepage-toptab组件和j-free-shipping免邮组件bottom差值
        const bottom = this.doms.relateTopElement?.getBoundingClientRect?.()?.bottom
        const compTop = this.doms.stickyJudge?.getBoundingClientRect?.()?.bottom
        if (compTop === 0) {
          this.isNeedSticky = false
          // this.calcStickyThrottling = false
          return
        }
       
        if (compTop < bottom) {
          // 需要吸顶
          this.isNeedSticky = true
        } else {
          this.isNeedSticky = false
        }
      }
      // this.calcStickyThrottling = false
    },
    /**
     * 返回数据给StickyFreeShippingInstance组件的props
     * @param {Array} showItems ccc配置的数组
     * @param {*} language 多语言
     * @param {*} getAnalysisData 
     * @param {Object} injectData 注入的各种类型的数据
     * @param {Number} stickyTop 吸顶的top值
     */
    getStickyCompProps() {
      return {
        showItems: this.showItems,
        language: this.language,
        getAnalysisData: this.getAnalysisData,
        injectData: this.injectData,
        stickyTop: this.stickyTop,
        isNewUserStyle: this.isNewUserStyle,
        isHasNewUserCoupon: this.isHasNewUserCoupon(this.showItems),
        handleTypeDrawerData: this.couponClickJump,
        activeIndex: this.isNewUserStyle ? this.swiperSlideIndex : this.stickySwiperSlideIndex,
        getSelfCoupon: this.getSelfCoupon,
      }
    },
    /**
     * 点击组件类型跳转
     * 免邮(type = 1): 免邮弹窗
     * 凑单优惠券(type = 2): 弹窗
     * 订单返券(type = 3): 弹窗
     * 优惠券倒计时(type = 6): 弹窗
     * 兜底(type = 4,5): 跳转到对应的链接
     */
    couponClickJump({ item, index, title }) {
      if(item.type === '4' || item.type === '5' || item.type === 'newUserCoupon') { // 兜底跳转
        const url = this.cccLink.getFullLink({
          item,
          compIndex: this.index,
          index: index,
          isBff: true,
        })
        this.jumpLink({ url, hrefType: item.hrefType })
      } else { // 组件弹窗
        switch(item.type) {
          case '1':
          case '100':
            this.freeshippingComp = {
              show: true,
              coupon: item.freeMallCouponInfo || [],
              item,
              isShowShippingCoupon: item.isShowShippingCoupon
            }
            break
          case '2':
            this.drawerComp = {
              show: true,
              title,
              coupon: this.handleCouponData(item.couponInfoList),
              item,
            }
            break
          case '3':
            this.drawerComp = {
              show: true,
              title,
              // this.injectData?.promotionInfo?.coupons
              coupon: item.couponInfoList || [], 
              item,
            }
            break
          case '6':
            this.drawerComp = {
              show: true,
              title,
              coupon: this.handleCouponData(item.couponInfoList),
              item,
            }
            break
        }
        // 点击监控
        this.clickCompMonitor()
      }
    },
    handleCouponData(item = []) {
      const newItem = [...item]
      // 处理优惠券规则
      const processCouponRule = (rule) => {
        const { max_order } = rule
        // 如果 max_order 的 priceAmount 为 '0'，赋值为 null
        if (max_order?.priceAmount == '0') {
          max_order.priceAmount = max_order.price = max_order.priceSymbol = null
        }
      }
      // 处理 item
      newItem?.forEach(element => {
        element?.other_coupon_rule?.forEach(processCouponRule)
      })
      // 重新赋值
      return newItem
    },
    async updateCartinfo(){
      const promotionId = this.showItems?.filter(item => item.type === '3')?.[0]?.promotionId
      this.shouldUpdateData = false
      const services = [getCartFloor({ promotionId })]
      if (!!this.injectData.hasPurchaseCoupon) {
        services.push(getCccPurchaseCouponBannerCompData({
          hashCode: this.injectData?.purchaseCouponInfo?.hashCode ?? '',
          pageScene: 'list' // 后端识别列表的标识
        }))
      }
      const [cartResult, { info: purchaseCouponInfo } = {} ] = await Promise.all(services)

      // 来自购物车信息更新券信息
      this.injectData.cartBriefInfo = cartResult.info
      this.injectData.siteFreeMallInfo = cartResult.info
      this.updatePurchaseCouponInfo(purchaseCouponInfo)
      // TODO: 接入凑单券后修改
      // (!purchaseCouponInfo || purchaseCouponInfo?.couponEmpty === '1') && this.clientRequestData()
      // 判断是否需要展示
      this.judgeToSticky()
    },
    // 是否含有免邮券
    async getFreeShippingCoupon(item) {
      const { freeShippingInfo } = item
      const { freeShippingCoupons = [] } = freeShippingInfo || {}
      const coupon = freeShippingCoupons[0]
      const result = await getFreeShippingCouponInfo({ couponCodes: coupon.coupon_code })
      this.injectData.freeShippingInfo = result?.info || {}
    },
    initJudgeShow() {
      if(this.showItems.length === 0) {
        if(this.onlySingleStylePage) {
          cccxEventBus?.emit?.('ccc-freeShippingComp-noDataNeedHide')
        }
        this.hideComp = true
        this.handleHideCompSls(3, 'web_client_home_error_total', 'CCCShownFilterError', 'itemsEmpty')
        return
      }
      // 是否依赖购物车的信息接口 type = 1 或者 type = 3 或者 type = 6
      const isNeedCartInfo = this.showItems.filter(item => item.type == '1' || item.type == '3' || item.type == '6')
      if (isNeedCartInfo.length) {
        // 购物信息通知
        cccxEventBus.on('free-shipping-update-cartinfo', async () => {
          this.shouldUpdateData = true
          // 如果是列表or收藏则立即触发
          if(this.canUpdateCartinfo) {
            this.$nextTick(() => {
              this.updateCartinfo()
            })
          }
        })
      }
      // 是否存在免邮券
      const isHasFreeShipping = this.showItems.filter(item => item.type == '1' && item?.freeShippingInfo?.freeShippingType == 'coupon')
      if (isHasFreeShipping.length) {
        this.getFreeShippingCoupon(isHasFreeShipping[0])
      }
    },
    /**
     * 子组件通知父组件改变的一些逻辑
     */
    async updateParentChange(parmas) {
      console.log('parmas: ', parmas)
      // if(parmas?.item?.type === 6) {
      //   // 倒计时结束触发 => 重新计算
      //   const oldList = this.injectData?.couponCountdown || []
      //   let abtParams
      //   if(this.noneAbtByCouponCountdown) {
      //     abtParams = null
      //   } else {
      //     abtParams = this.IconCountdownAbt.includes('24') ? '24h' : (this.IconCountdownAbt.includes('48') ? '48h' : null)
      //   }
      //   // 优惠券排序
      //   const couponCountdownList = await this.coponListSort([...oldList], abtParams)
      //   this.injectData.couponCountdown = JSON.parse(JSON.stringify(couponCountdownList))
      //   // 同步在判断展示的组件 => 根据倒计时等条件判断是否要隐藏优惠券组件
      //   this.initJudgeShow(this.injectData)

      //   // 是优惠券倒计时弹窗 && 弹窗是打开状态 => 同步优惠券倒计时的数据（已过滤过期）
      //   if(this.drawerComp?.item?.type === 6 && this.drawerComp?.show ) {
      //     // 优惠券倒计时数据长度大于0
      //     if(this.injectData?.couponCountdown?.length) {
      //       this.drawerComp.coupon = this.injectData?.couponCountdown ? [...this.injectData?.couponCountdown] : []
      //     } else {
      //       // 没有数据了 => 关闭弹窗
      //       this.drawerComp.show = false
      //     }
      //   }
      // }
    },
    // 异常日志上报
    handleHideCompSls(logLevel, tag, message, reason) {
      this.hideCompSls({
        logLevel,
        tag,
        message,
        reason,
      })
    },
    // 更新凑单券
    updatePurchaseCouponInfo(purchaseCouponInfo) {
      // 当两次hashcode不一致时需要更新数据
      const isPurchaseCouponInfoUpdate = purchaseCouponInfo?.hashCode !== this.injectData.purchaseCouponInfo?.hashCode
      if (isPurchaseCouponInfoUpdate) {
        if (+purchaseCouponInfo?.couponEmpty) {
          this.injectData.purchaseCouponInfo = null
        } else {
          this.injectData.purchaseCouponInfo = purchaseCouponInfo
        }
      }
    },
  }
}
</script>
<style lang="less">
/* stylelint-disable */
.free-shipping__container {
  position: relative;
  min-height: 72/75rem;
  display: flex;
  &.only-single {
    .free-shipping-left {
      margin-right: 0px;
      width: 100%;
      height: 32px;
    }
    .free-shipping-right {
      width: 0;
    }
  }
}
.free-shipping-right__swiperRight {
  height: 48px;
  width: 100%;
  overflow: hidden;
}
.free-shipping-new-user__swiper {
  width: 100%;
}
.free-shipping-right__culSwiper {
  width: 100%;
  height: 100%;
}
.free-shipping-right__swiperRightSlide {
  height: 100%;
  margin-bottom: 0px;
}
.free-shipping__inner {
  display: flex;
  // margin: 0 0.2133rem;
  width: 100%;
  height: 48px;
  overflow: hidden;
  .free-shipping-left {
    width: calc((100% - 4px) / 2);
    margin-right: 4px;
    display: flex;
    .free-shipping {
      border-radius: 2px;
    }
  }
  .free-shipping-right {
    width: calc((100% - 4px) / 2);
    display: flex;
    .free-shipping {
      border-radius: 2px;
    }
  }
  .no-spacing {
    margin-right: 0;
    width: 50%;
  }
  .has-border {
    content: '';
    position: absolute;
    z-index: 1;
    right: 50%;
    top: 20%;
    width: 0.5px;
    height: 58%;
  }
  // 未吸顶主标题
  .free-shipping-coupon__title-txt {
    font-size: 13px;
  }
}

// 仅应用吸顶样式轮播
.free-shipping__stickyInner {
  display: flex;
  padding: 0;
  width: 100%;
  height: 72/75rem;
  border-radius: .0533rem;
}
.free-shipping__stickyInnerSwiper {
  width: 100%;
}
.free-shipping__stickyInnerSlide {
  width: 100%;
}
.free-shipping__onlySingleStylePage {
  overflow: hidden;
}
.free-shipping__onlySingleStylePage .swiper-wrapper { // 解决服务端渲染swiper还未初始化的样式问题
  flex-direction: column;
}

// fixed吸顶
.free-shipping {
  width: 100%;
  height: 100%;
  background-color: #fff;
  &__sticky-block {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 13;
  }
}

// 吸顶时候的样式
.free-shipping.is-fixed {
  display: block;
  animation: fadeIn .3s;
  height: 72/75rem;
  overflow: hidden;
  background-color: #fff;
  

  .free-shipping-coupon {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    animation: stickyIn .3s;
    padding-left: .16rem;
    padding-right: .16rem;
    border-radius: 0px;
  }

  .free-shipping-coupon__title-icon {
    padding: 0 8px 0 0;
    line-height: 16px;
    font-size: 16px;
  }
  .free-shipping-coupon__title-txt {
    font-size: 11px;
  }
  .free-shipping-coupon__title {
    height: 100%;
    display: flex;
    white-space: unset;
    padding-bottom: 1px;
    padding-right: 4px;
    flex: 1;
  }
}

.free-shipping__fixed-swiper {
  position: relative;
  height: 54px;
  .swiper-slide {
    height: 100% !important;
  }
}

// 优惠券样式
.free-shipping-coupon {
  height: 100%;
  flex:1;
  padding: 0 6px 0 12px;
  position: relative;
  border-radius: 2px;
  &__title-icon {
    display: inline-block;
    font-size: 12px;
    vertical-align: middle;

    .base-img {
      width: 0.3467rem;
    }
  }

  &__title-txt {
    font-family: -apple-system, BlinkMacSystemFont, 'SF UI Text', "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    position: relative;
    display: inline-block;
  }

  &__title {
    font-family: -apple-system, BlinkMacSystemFont, 'SF UI Text', "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 700;
    font-size: 12px;
    color: #000;
    vertical-align: middle;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__sub-info {
    font-family: -apple-system, BlinkMacSystemFont, 'SF UI Text', "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 12px;
    // transform: scale(0.83);
    color: #666666;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 2;
  }
}

// 双列高度整体
.free-shipping:not(.is-fixed) {
  .free-shipping-coupon {
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .free-shipping-coupon.onlyTitle {
    justify-content: flex-start;
    padding-top: 6px;
  }
}

// 优化直出样式
.is-single {
  height: 72/75rem;
  swiper-container,swiper-slide {
    display: block;
    height: 100%;
  }
}
// 新人列表导航样式
.is-single-newUser {
  height: .8533rem;
  swiper-container,swiper-slide {
    display: block;
    height: 100%;
  }
}
// 右下角图标
.freeshipping-rb {
  position: absolute;
  height: 16px;
  bottom: 6px;
  right: 6px;
  z-index: 1;
}
.freeshipping-rb-icon {
  position: relative;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.freeshipping-rb-icon i{
  line-height: 1;
}
.freeshipping-rb-layout {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 16px;
  height: 16px;
  display: inline-block;
}
// 单列吸顶图标
.sticky-single__icon-wrap {
  position: relative;
  .sticky-single-flex {
    position: relative;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sticky-single-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 16px;
    height: 16px;
    display: inline-block;
  }
}
.promo-color {
  font-weight: 700;
}

.color-success {
  color: #198055;
}

@keyframes stickyIn {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>

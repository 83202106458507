<!-- 首页弹窗轻量展示 -->
<script lang="jsx">
import { template } from '@shein/common-function'
export default {
  name: 'ShippingBoxForHome',
  functional: true,
  props: {
    language: {
      required: true,
      type: Object,
      default() {
        return {}
      }
    },
    shippingLanguage: {
      required: true,
      type: Object,
      default() {
        return {}
      }
    },
    shippingInfo: {
      required: true,
      type: Object,
      default() {
        return {}
      }
    },
    shippingPolicy: {
      required: true,
      type: Array,
      default() {
        return []
      }
    },
    freeShippingThreshold: {
      type: String,
      default: ''
    },
    freeShippingMethods: {
      type: String,
      default: ''
    }
  },
  render (h, { props }) {
    const isEmpty = (target) => Object.keys(target).length === 0
    const { shippingInfo, language, shippingPolicy, shippingLanguage } = props
    const getShippingToHeader = () => {
      // 无国家时不展示
      if (isEmpty(shippingInfo)) return null
      return (
        <div class="mini-shipping-box__container shippingInfoBlock">
          <div>
            <span class="mini-shipping-box__title">{ language.SHEIN_KEY_PWA_16266 }</span>
            <p class="mini-shipping-box__text">
              { shippingInfo.country } { shippingInfo.state_province } { shippingInfo.city } { shippingInfo.district }
            </p>
          </div>
        </div>
      )
    }
    const showShippingMethods = () => {
      if (!shippingPolicy.length) return false
      const freeShipping = shippingPolicy.filter(item => {
        return (
          Number(item.hasZeroThreshold) === 1 ||
          (Number(item?.startingPriceObj?.amount) === 0 &&
            Number(item?.stepPriceObj?.amount) === 0) ||
          (Number(item?.startingPriceObj?.amount) > 0 &&
            Number(item?.fullAmount?.amount) > 0)
        )
      })
      return freeShipping.length || false
    }
    const getPolicyBox = (val) => {
      const isPolicyFree = val.hasZeroThreshold && Number(val.hasZeroThreshold) === 1
      if (isPolicyFree) {
        return <span class="infoText">{ shippingLanguage[val.type + '_free'] }</span>
      }
      const zeroStartingPriceObjAmount = val.startingPriceObj && Number(val.startingPriceObj.amount) === 0
      const posStartingPriceObjAmount = val.startingPriceObj && Number(val.startingPriceObj.amount) > 0
      if(val.transport_type === props.freeShippingMethods && props.freeShippingThreshold){
        return <span
          class="infoText"
          domPropsInnerHTML={template(props.freeShippingThreshold, shippingLanguage[val.type], shippingLanguage[val.type])}
        ></span> 
      } else if (zeroStartingPriceObjAmount) {
        return <span class="infoText">{ shippingLanguage[val.type + '_free'] }</span>
      } else if (posStartingPriceObjAmount) {
        let amountWithSymbol = val.fullAmount.amountWithSymbol
        const hasFullAmount = val.fullAmount.amount > 0
        return (
          hasFullAmount ?
            <span
              class="infoText"
              domPropsInnerHTML={template(amountWithSymbol, shippingLanguage[val.type], shippingLanguage[val.type])}
            ></span> 
            : null
        )
      }
    }
    const getShippingInfo = () => {
      let backFreePolicy = shippingPolicy.filter(val => val.is_support_free_shipping != 0)
      if (isEmpty(backFreePolicy)) return null
      return (
        <div class="mini-shipping-box__container">
          <div class="mini-shipping-box__infoTitle">{ language.SHEIN_KEY_PWA_15217 }</div>
          {showShippingMethods() && (
            <div class="mini-shipping-box__info">
              {backFreePolicy.map(val => getPolicyBox(val) ? <div class="infoTextBlock">{getPolicyBox(val)}</div> : null )}
            </div>
          )}
        </div>
      )
    }
    return (
      <div class="mini-shipping-box">
        {getShippingToHeader()}
        {getShippingInfo()}
      </div>
    )
  }
}
</script>

<style lang="less">
  .mini-shipping-box {
    display: flex;
    flex-direction: column;
    gap: 0.32rem;
    &__title {
      font-size: 0.3733rem;
      color: @sui_color_gray_dark3;
    }
    &__text {
      margin-top: 0.1067rem;
      font-size: 0.3733rem;
      color: @sui_color_gray_dark1;
    }
    &__infoTitle {
      color: @sui_color_gray_dark1;
      font-weight: 700;
      font-size: 0.3733rem;
    }
    &__info {
      display: flex;
      flex-direction: column;
      gap: 0.1067rem;
      padding: 0.4267rem 0;
      .infoTextBlock {
        margin-bottom: 4px;
      }
      .infoText {
        color: @sui_color_success;
        margin-bottom: 4px;
        &:before {
          content: "";
          display: inline-block;
          width: 0.1067rem;
          height:  0.1067rem;
          background-color: @sui_color_success;
          vertical-align: middle;
          border-radius: 0.0533rem;
          font-weight: 400;
          .margin-r(0.1rem);
        }
      }
    }
    &__container {
      padding: 0.32rem 0.32rem 0;
      background: #fff;
      line-height: normal;
      font-weight: 400;
      font-size: 0.32rem;
    }
    &__container.shippingInfoBlock {
      padding-bottom: 0.32rem;
    }
  }
</style>



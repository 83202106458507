<template>
  <s-drawer
    custom-class="cccxfreeShipping-freeshippingDialog drawer__no-bottom"
    max-size="70%"
    :type="'multi'"
    :append-to-body="true"
    :visible.sync="localVisible"
    @close="closeDrawer"
  >
    <template slot="top">
      <div
        class="freeshippingDrawerTitle"
        v-html="freeshippingTitle"
      ></div>
    </template>
    <div>
      <div
        v-if="!item.useCoupon"
        class="freeshipping-list"
        :class="{'onlyOne': onlyOne,
                 'onlyTwo': onlyTwo}"
      >
        <div
          v-if="showProgress"
          class="freeshipping-achieved"
        >
          <p class="title">
            {{ language.SHEIN_KEY_PWA_24825 }} {{ progressWidth }}%
          </p>
          <!-- 进度条 -->
          <div class="progress-wrap">
            <p 
              class="progress"
            >
              <span
                class="progress-bar"
                :style="{
                  width: `${progressWidth}%`,
                }"
              ></span>
            </p>
          </div>
        </div>
        <div
          v-if="onlyTwo"
          class="freeshipping-divide"
        ></div>
        <div
          v-if="showCountdown"
          class="freeshipping-endin"
        >
          <div class="title">
            {{ language.SHEIN_KEY_PWA_25713 }}
          </div>
          <section
            class="free-shipping-coupon__sub-info countdown"
          >
            <div
              class="box"
              style="direction: ltr"
            >
              <template v-if="Number(countTimer.timeObj.D) > 0">
                <span class="digit-num digit-d-num">{{ countTimer.timeObj.D }}</span> D
              </template><span class="digit-num">{{ countTimer.timeObj.H }}</span>:<span class="digit-num">{{ countTimer.timeObj.M }}</span>:<span class="digit-num">{{ countTimer.timeObj.S }}</span>
            </div>
          </section>
        </div>
      </div>
      <div
        v-if="showFreeCoupon"
        :class="{'coupon-background-use-coupon': item.useCoupon}"
        class="coupon-background"
      >
        <coupon-item
          :key="`${sortedCoupon?.id}`"
          :style="{ 'margin': '0 12px 16px'}"
          :show-coupon-real-discount="true"
          :item="sortedCoupon"
          :language="language"
          :abt-coupon-apply="false"
          :is-show-newly="true"
          :show-coupondown="isShowShippingCoupon"
          :theme-config="getThemeConfig(sortedCoupon)"
          :is-expired="sortedCoupon.coupon_status == '4'"
          :expanded="expandedCoupon"
          show-stackable-tips
          @triggerExpand="triggerExpand"
          @triggerCollapse="expandedCoupon = ''"
        />
      </div>
      <div
        v-if="!couponInfo.length && item.useCoupon"
        class="no-coupon"
      >
        <div>
          <BaseImg
            :img-src="noCouponImg"
            :img-design-width="75"
            :first-screen="false"
          />
        </div>
        <span>{{ language.SHEIN_KEY_PWA_31034 }}</span>
      </div>
      <ShippingBox 
        class="freeshipping-box"
        :language="language"
        :drawer-visible="visible"
        :free-shipping-threshold="freeShippingThreshold"
        :free-shipping-methods="freeShippingMethods"
      />
    </div>
    <template slot="footer">
      <s-button
        class="freeshipping-cancel-btn"
        :width="'100%'"
        @click="closeDrawer"
      >
        {{ language.SHEIN_KEY_PWA_21364 }}
      </s-button>
    </template>
  </s-drawer>
</template>
<script>
import { Button as SButton, Drawer as SDrawer } from '@shein/sui-mobile'
import ShippingBox from './ShippingBox.vue'
import { template } from '@shein/common-function'
import couponItemVue from 'public/src/pages/components/coupon/mall/index.vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { getThemeConfig } from 'public/src/pages/components/coupon/mall/utils'
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg'


export default {
  components: {
    SButton,
    SDrawer,
    ShippingBox,
    couponItem: couponItemVue,
    // eslint-disable-next-line vue/no-unused-components
    Icon,
    BaseImg,
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isShowShippingCoupon: {
      type: Boolean,
      default: false
    },
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    couponInfo: {
      type: Array,
      default() {
        return []
      }
    },
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    siteFreeMallInfo: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  data() {
    return {
      localVisible: this.visible,
      expandedCoupon: '',
    }
  },
  computed: {
    threshold() {
      return this.item?.threshold
    },
    diffValue() {
      return this.item?.diffValue
    },
    progressWidth() {
      return this.item?.progressWidth
    },
    countTimer() {
      return this.item?.countTimer
    },
    showProgress() {
      return this.item?.showProgress && !this.threshold 
    },
    showCountdown() {
      return this.item?.showCountdown
    },
    // 是否只有进度条或倒计时其中之一
    onlyOne() {
      return (this.showProgress && !this.showCountdown) || (!this.showProgress && this.showCountdown)
    },
    onlyTwo() {
      return this.showProgress && this.showCountdown 
    },
    freeshippingTitle() {
      if(this.diffValue && !this.threshold) {
        return template(`<span class='promo-color'>${this.diffValue}</span>`, this.language.SHEIN_KEY_PWA_24791 && this.language.SHEIN_KEY_PWA_24791.replace(/{.*}/, '{0}'))
      }else if(this.threshold){
        return `<Icon name="sui_icon_selected_16px" size="16px" color="#169E00" class="color-success"></Icon>${this.language.SHEIN_KEY_PWA_24790}`
      }else {
        return ''
      }
    },
    noCouponImg() {
      return 'https://img.ltwebstatic.com/images3_ccc/2024/05/09/d2/1715258693e8d3a98917d277ab0403698b7cbca98e.png'
    },
    // TODO: 优化 删除
    sortedCoupon() {
      // const sortedCoupons = this.couponInfo.filter(coupon => 
      //   coupon.shippingDiscountType === 0
      // ).sort((a, b) => {
      //   const difference = parseFloat(a.rule[0].minAmount.amount) - parseFloat(b.rule[0].minAmount.amount)
      //   if (difference !== 0) {
      //     return difference
      //   }
      //   return parseInt(a.endTime) - parseInt(b.endTime)
      // })
      // if (sortedCoupons[0].applyFor) {
      //   sortedCoupons[0].apply_for = sortedCoupons[0].applyFor
      // }
      return this.couponInfo[0] || {}
    },
    // 是否展示免邮券
    showFreeCoupon() {
      return this.localVisible && this.couponInfo && this.couponInfo.length && this.item.useCoupon
    },
    // 更新免邮门槛值
    freeShippingThreshold() {
      if(this.showFreeCoupon) return ''
      return (this.siteFreeMallInfo?.free_threshold?.amountWithSymbol || this.item?.couponRules?.[0]?.thresholdPrice?.amountWithSymbol || '')
    },
    // 更新免邮运输方式
    freeShippingMethods() {
      if(this.showFreeCoupon) return ''
      return (this.siteFreeMallInfo?.freeTransportType || this.item?.freeTransportType || '')
    },
  },
  watch: {
    visible (newVal) {
      this.localVisible = newVal
    },
    localVisible (newVal) {
      this.$emit('update:visible', newVal)
    }
  },
  created () {
  },
  mounted() {
  },
  updated() {
  },
  methods: {
    template,
    getThemeConfig,
    closeDrawer() {
      this.localVisible = false
    },
    triggerExpand (id) {
      this.expandedCoupon = id
    },
  }
}
</script>

<style lang="less">
/* stylelint-disable */
.cccxfreeShipping-freeshippingDialog.drawer__no-bottom {
  border-radius: 6px 6px 0 0;
  .S-drawer__header_normal {
    height: 100%;
    min-height: unset;
    line-height: 1;
    border-bottom: none;
  }
  .freeshippingDrawerTitle {
    padding: 0.74rem 16px 16px;
    display: inline-block;
    font-size: 16px;
    text-align: center;
    color: #000;
    font-weight: 700;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 20px;
    -webkit-line-clamp: 2;
  }
  .freeshipping-list{ 
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 12px 20px;
  }
  .freeshipping-achieved {
    position: relative;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 30px;
    height: 30px;
    .title{
      padding-bottom: 8px;
    }
    .progress-wrap {
      position: relative;
      width: 100%;
      height: 15px;
    }
    .progress {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      
      height: 3px;
      background-color: rgba(0, 0, 0, 0.06);
      border-radius: 1.5px;
      overflow: hidden;
      margin: 6px 0;
      // padding-bottom: 12px;
    }
    .progress-bar {
      position: absolute;
      height: 100%;
      background-color: #222222;
      width: 30%;
    }
  }
  .freeshipping-divide {
    width: 2px;
    height: 16px;
    background-color: rgba(0, 0, 0, 0.05);;
  }
  .freeshipping-endin {
    position: relative;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 30px;
    .title{
      padding-bottom: 3px;
      width: auto;
    }
    .countdown {
      display: flex;
      z-index: 2;
      width: auto;
      .box {
        display: inline-block;
        .digit-num {
          background: #000;
          text-align: center;
          padding: 1px;
          color: #fff;
          width: 18px;
          height: 18px;
          line-height: 16px;
          transform: scale(0.82);
          transform-origin: center;
          display: inline-block;
          margin: 0 1px;
          border-radius: 2px;
        }
        .digit-d-num {
          width: auto;
          min-width: 18px;
        }
      }
    }

  }
  .freeshipping-list.onlyOne{
    width: 100%;
    .title {
      padding-bottom: 0px;
      line-height: 16px;
      margin-right: 4px;
    }
    .freeshipping-achieved, .freeshipping-endin {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
    }
    
    .progress-wrap {
      position: relative;
      flex: 1;
      height: 16px;
      line-height: 16px;
    }
    .progress {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      
      height: 3px;
      background-color: rgba(0, 0, 0, 0.06);
      border-radius: 1.5px;
      overflow: hidden;
      margin: 6px 0;
      // padding-bottom: 12px;
    }
    .progress-bar {
      position: absolute;
      height: 100%;
      background-color: #222222;
      width: 30%;
    }
    .freeshipping-endin {
      justify-content: center
    }
  }
  .freeshipping-list.onlyTwo{
  }
  .freeshipping-box {
    padding: 0;
    border-top: 1px solid #F6F6F6;
    background-color: #F6F6F6;
  }
  .coupon-background {
    border-bottom: 0.32rem solid rgba(246,246,246);
  }
  .no-coupon{
    font-family: SF UI Text;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.71px;
    text-align: center;
    color: #999999;
    padding-bottom: 38px;
    align-items: center;
    border-top: 1px solid #f6f6f6;
    border-bottom: 0.32rem solid rgba(246,246,246);
  }
  .no-coupon > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75px; 
    width: 75px; 
    margin: 16px auto; 
  }
  .promo-color {
    /*sh:begin*/
    color: #FA6338;
    /*rw:begin*/
    color: #FC4070;
  }
  .freeshipping-cancel-btn {
    border: 0px;
    color: @sui_color_white;
    /*sh:begin*/
    background: @sui_color_gray_dark1;
    /*rw:begin*/
    background: #FC4070;
  }
  .coupon-countdown__time span {
    background-color: #169E00;
  }
  .S-drawer__close-btn {
    color: #000000 !important;
    font-size: 16px;
    margin: 6px 0 0 12px;
  }
}
</style>

<template>
  <s-drawer
    :id="`cccCouponInfoDrawer-${page}`"
    custom-class="cccxfreeShipping-couponDialog drawer__no-bottom"
    max-size="70%"
    :type="'multi'"
    :append-to-body="true"
    :visible.sync="localVisible"
    @close="closeDrawer"
  >
    <template slot="top">
      <div
        class="freeshippingCouponTitle"
        v-html="title"
      >
      </div>
    </template>
    <div v-if="localVisible && couponInfo && couponInfo.length">
      <!-- 凑单和订单返券不显示倒计时 -->
      <coupon-item
        v-for="(coupon) in couponInfo"
        :key="`${coupon.coupon}_${coupon.bind_time}`"
        :show-coupon-real-discount="true"
        :item="coupon"
        :language="language"
        :abt-coupon-apply="false"
        :is-show-newly="false"
        :show-coupondown="isShowCoupondown"
      />
    </div>
    <template slot="footer">
      <s-button
        class="freeshipping-cancel-btn"
        :width="'100%'"
        @click="closeDrawer"
      >
        {{ language.SHEIN_KEY_PWA_21364 }}
      </s-button>
    </template>
  </s-drawer>
</template>
<script>

import { Button as SButton, Drawer as SDrawer } from '@shein/sui-mobile'
import couponItemVue from 'public/src/pages/components/coupon/mall/index.vue'
import { template } from '@shein/common-function'
export default {
  components: {
    SButton,
    SDrawer,
    couponItem: couponItemVue
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    couponInfo: {
      type: Array,
      default() {
        return []
      }
    },
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    title: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    page: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      localVisible: this.visible
    }
  },
  computed: {
    isShowCoupondown() {
      // 只有优惠券倒计时类型才展示倒计时
      return this.item?.type == 6
    }
  },
  watch: {
    visible (newVal) {
      this.localVisible = newVal
    },
    localVisible: {
      handler(newVal) {
        this.$emit('update:visible', newVal)
        if(this.localVisible && this.page === 'category') {
          setTimeout(() => {
            this.$nextTick(() => {
              const scrollCoupon = document.querySelector(`#cccCouponInfoDrawer-${this.page} .S-drawer__body`)
              scrollCoupon?.addEventListener('touchmove', function(e) {
                e.stopPropagation()
              }, { passive: false })
            })
          }, 500)
        }
      },
      immediate: true,
    }
  },
  created () {
  },
  mounted() {
  },
  updated() {
  },
  methods: {
    template,
    closeDrawer() {
      this.localVisible = false
    }

  }
}
</script>

<style lang="less">
.cccxfreeShipping-couponDialog.drawer__no-bottom {
   border-radius: 6px 6px 0 0;
   .S-drawer__body {
    padding: 0 12px;
  }
  .S-drawer__header_normal {
    height: 100%;
    min-height: unset;
    line-height: 1;
    border-bottom: none;
  }
  .freeshippingCouponTitle {
    padding: 0.74rem 20px 20px;
    display: inline-block;
    font-size: 16px;
    text-align: center;
    color: #000;
    font-weight: 700;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 20px;
    -webkit-line-clamp: 2;
  }
  .promo-color {
    /*sh:begin*/
    color: #FA6338;
    /*rw:begin*/
    color: #FC4070;
  }
  .S-drawer__multi-footer {
    padding: 0 12px 6px;
  }
  .freeshipping-cancel-btn {
    border: 0px;
    color: @sui_color_white;
    /*sh:begin*/
    background: @sui_color_gray_dark1;
    /*rw:begin*/
    background: #FC4070;
  }
}
</style>

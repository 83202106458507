import Vue from 'vue'
import StickyFreeShippingVue from './StickyFreeShipping.vue'

let instance = null

function createInstance(params) {
  try {
    const StickyFreeShipping = Vue.extend(StickyFreeShippingVue)
    if(instance) {
      console.log('sticky-free-shipping instance is exist')
      return
    }
    instance = new StickyFreeShipping({
      el: document.createElement('div'),
      propsData: params,
    })
    document.body.appendChild(instance.$el)
    console.log('sticky-free-shipping create success')
    instance.show(params)
  } catch (error) {
    console.log(error)
  }
}

export default {
  show(params) {
    if (!instance) {
      createInstance(params)
    } else {
      // 更新props
      Object.assign(instance, {
        ...params
      })
      instance.show(params)
    }
  },
  update(params) {
    if (instance) {
      // 更新props
      instance.update(params)
    }
  },
  hidden() {
    if (instance) {
      return instance.hidden()
    }
  }
}

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.visibleComp)?_c('div',{staticClass:"free-shipping free-shipping__sticky-block is-fixed",class:{
    'free-shipping__sticky-block--new': _vm.isNewUserStyle
  },style:({
    top: _vm.stickyTopPx,
    height: _vm.isNewUserStyle ? '.8533rem' : '',
    backgroundColor: _vm.containerStyle?.backgroundColor || '' 
  })},[_c('swiper-container',{ref:"swiperReferenceSticky",staticClass:"free-shipping__fixed-swiper",attrs:{"init":"false"}},[(!_vm.isNewUserStyle)?_vm._l((_vm.showItems),function(item,index){return _c('swiper-slide',{key:`slide-${item.type}-${item.hrefType}-${index}`,staticClass:"swiper-no-swiping"},[_c('FreeShippingList',_vm._b({staticClass:"free-shipping is-fixed",attrs:{"items":[item],"is-sticky":true,"custom-component-name":_vm.customComponentName,"language":_vm.language.freeshipping,"get-analysis-data":_vm.getAnalysisData,"is-show-shipping-coupon":_vm.isShowShippingCoupon},on:{"clickCoupon":_vm.handleTypeDrawerData,"getContainerStyle":_vm.getContainerStyle}},'FreeShippingList',_vm.injectData,false))],1)}):_vm._e(),_vm._v(" "),(_vm.isNewUserStyle && _vm.showItems.length > 0)?_c('swiper-slide',{key:"newUserCoupon-sticky",staticClass:"swiper-no-swiping"},[_c('FreeShippingList',_vm._b({staticClass:"free-shipping is-fixed",style:({ 'height': '.8533rem' }),attrs:{"is-sticky":true,"show-items":_vm.showItems,"is-new-user-style":_vm.isNewUserStyle,"get-analysis-data":_vm.getAnalysisData,"language":_vm.language.freeshipping,"is-show-shipping-coupon":_vm.isShowShippingCoupon},on:{"clickCoupon":_vm.handleTypeDrawerData}},'FreeShippingList',_vm.injectData,false))],1):_vm._e(),_vm._v(" "),(_vm.isNewUserStyle && _vm.isHasNewUserCoupon.length > 0)?_c('swiper-slide',{key:"newUserCoupon-sticky-new",staticClass:"swiper-no-swiping"},[_c('FreeShippingList',_vm._b({staticClass:"free-shipping is-fixed",style:({ 'height': '.8533rem' }),attrs:{"is-sticky":true,"is-has-new-user-coupon":_vm.isHasNewUserCoupon.length > 0,"is-new-user-style":_vm.isNewUserStyle,"get-analysis-data":_vm.getAnalysisData,"items":_vm.isHasNewUserCoupon,"is-show-shipping-coupon":_vm.isShowShippingCoupon,"language":_vm.language.freeshipping},on:{"clickCoupon":_vm.handleTypeDrawerData}},'FreeShippingList',_vm.injectData,false))],1):_vm._e()],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div 
    class="newUserStyle"
    :style="labelBgColor"
  >
    <div class="text">
      {{ language?.SHEIN_KEY_PWA_30937 }}
    </div>
    <div
      class="icon"
      :class="{'icon-right': GB_cssRight }"
      :style="iconBgColor"
    ></div>
  </div>
</template>
<script>
import { hexToRGBA } from 'public/src/pages/components/ccc/common/utils.js'
const { GB_cssRight } = typeof window !== 'undefined' ? gbCommonInfo : {}
export default {
  name: 'NewUserText',
  props: {
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    bgColor: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      GB_cssRight
    }
  },
  computed: {
    labelBgColor() {
      return {
        background: `linear-gradient(90deg, ${this.bgColor?.labelBgColorStart} 0%, ${this.bgColor?.labelBgColorEnd} 40%, ${this.bgColor?.labelBgColorEnd} 90%, ${this.bgColor?.labelBgColorStart} 100%)`,
        border: `0.5px solid ${hexToRGBA(this.bgColor?.labelBgColorEnd, 1)}`
      }
    },
    iconBgColor() {
      return {
        background: this.bgColor?.labelBgColorEnd
      }
    }
  }
}

</script>
<style lang="less" scoped>
.newUserStyle {
  position: absolute;
  right: -2px;
  top: -0.16rem;
  width: 1.68rem;
  height: .3467rem;
  flex-shrink: 0;
  z-index: 1;
  padding: 0 .0533rem;
  border-bottom-left-radius: .08rem;
  border-top-right-radius: .08rem;
  .text {
    color: #FFF;
    text-align: center;
    font-size: .2133rem;
    font-style: normal;
    font-weight: 600;
    text-transform: capitalize;
    .text-overflow()
  }
  .icon {
    position: absolute;
    right: -0.5px;
    bottom: -2px;
    width: 2px;
    height: 2px;
    clip-path: polygon(0 0, 0 100%, 100% 100%);
    transform: rotate(90deg);
  }
  .icon-right {
    transform: rotate(180deg);
  }
}
</style>

import { getSiteFreeMallApi } from 'public/src/services/api/cartAddItem.js'
import { getLocalStorage } from '@shein/common-function'
import schttp from 'public/src/services/schttp'

const MAP = {
  'home': 'home',
  'category': 'nav',
  'list': 'list'
}
// 扩展埋点字段
export const extendBury = (items) => {
  // 给items 加上埋点属性 item_ioc
  items.forEach((item, index) => {
    item.item_loc = index + 1
  })
  return items
}
// let 
let countryFreeMallInfo
// refresh 是否需要刷新
export const getSiteFreeMall = async (refresh) => {
  if (countryFreeMallInfo && !refresh) return countryFreeMallInfo
  let cache = null
  const addressCookie = getLocalStorage('addressCookie')
  if (addressCookie) {
    try {
      cache =  typeof addressCookie === 'string' ? JSON.parse(addressCookie) : addressCookie
    } catch (error) {
      console.log('getSiteFreeMall', error)
    }
  }
  const result = await getSiteFreeMallApi({
    countryId: cache?.countryId || ''
    // || 226
  })
  if (result && result.data?.code == 0) {
    // eslint-disable-next-line require-atomic-updates
    countryFreeMallInfo = result.data
    return result.data
  }
  return null
}
/**
 * 获取国家id
 * @returns {String}
 */
export const getCountryId = () => {
  let countryId = ''
  if (typeof window === 'undefined') return countryId
  let addressCookie = window.localStorage.getItem('addressCookie')
  try {
    addressCookie = JSON.parse(addressCookie)
    countryId = addressCookie?.countryId ?? ''
  } catch {
    return countryId
  }
  return countryId
}
// 获取购物车楼层信息
export const getCartFloor = async ({ promotionId }) => {
  const { currency, appLanguage } = window.gbCommonInfo
  const country_id = getCountryId()
  // 此处app中间层要求请求体格式为formData
  const formData = new FormData()
  formData.append('country_id', country_id)
  if (promotionId) {
    formData.append('promotionId', promotionId )
  }
  return schttp({
    method: 'POST',
    url: '/order/cart/get_entrance_guide',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      'AppCurrency': currency,
      'AppLanguage': appLanguage,
      'frontend-scene': 'page_web_common'
    },
    useBffApi: true
  })
}

// 新客免邮刷新接口
/**
 * isNewUser ：是否新客，0：非新客，1：新客  （新客帧，默认传 1）
   newCustomerScene：新客场景下区分udp调用下单返(首页-home，导航分类-nav,列表-list)
 * @returns 
 */
export const getNewUserFreeShipping = async ({ sense }) => {
  const params = {
    isNewUser: '1',
    newCustomerScene: MAP[sense]
  }
  return schttp({
    method: 'POST',
    params,
    url: '/ccc/free_shipping/new_user_coupon/update',
    useBffApi: true
  })
}

// 免邮券接口
/**
 * 获取免邮券
 * @returns 
 */
export const getFreeShippingCouponInfo = async (params) => {
  return schttp({
    method: 'GET',
    url: '/ccc/free/shipping/coupon/list',
    params,
    useBffApi: true
  })
}

<template>
  <ClientOnly>
    <slot></slot>
  </ClientOnly>
</template>

<script>
import ClientOnly from 'vue-client-only'

export default {
  name: 'ClientOnlySlot',
  components: {
    ClientOnly
  }
}
</script>
